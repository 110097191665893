/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {TokenResponse} from "pcs-commons/datatypes";

@Component({
  selector: 'pcs-local-login',
  templateUrl: './local-login.component.html',
  styleUrls: ['./local-login.component.css']
})
export class LocalLoginComponent implements OnInit {

  public login: FormControl<string>;
  @Output() public tokenReceiveEvent = new EventEmitter<TokenResponse>();
  @Input() private defaultLogin: string;

  constructor(private http: HttpClient) {
  }

  public ngOnInit(): void {
    this.login = new FormControl<string>(this.defaultLogin);
  }

  public doMockLogin(): void {
    const loginValue = this.login.value;
    if (!loginValue) {
      return;
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });
    const uri = 'http://localhost:8080/mock-porsche-1.0-SNAPSHOT/aws/cognito/token/' + loginValue;
    this.http.get<TokenResponse>(uri, {headers: headers}).subscribe({
      next: val => this.tokenReceiveEvent.emit(val)
    });
  }
}
