<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-toolbar class="headerToolbar mat-elevation-z1">
  <span class="headerSpan">{{ "statistic.title" | translate }}</span>
</mat-toolbar>
<div class="section-container">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true" hideToggle disabled="true" class="statistic-expansion-panel">
      <mat-expansion-panel-header>
      </mat-expansion-panel-header>
      <mat-form-field class="statisticSelection">
        <mat-label>{{ "statistic.label.report" | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedQuery" name="queries" class="statistic">
          <mat-option [value]="i" *ngFor="let i of queries">{{ 'statistic.report.' + i | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="chunkSize" *ngIf="queryHasChunkSize">
        <mat-label>{{ "statistic.label.chunksize" | translate }}</mat-label>
        <input matInput [formControl]="pageSize" type=number class="chunkSizeInput">
        <mat-error *ngIf="pageSize.invalid">{{getError(pageSize) | async}}</mat-error>
      </mat-form-field>

      <div class="dateSelection">
        <mat-form-field *ngIf="!isTimeIndependentQuery && !onlyMonthAndYear" class="fromDatepicker">
          <mat-label>{{ "statistic.label.fromdate" | translate }}</mat-label>
          <input matInput *ngIf="!onlyMonthAndYear" [(ngModel)]="fromDate" [matDatepicker]="pickerFromDate" disabled
                 [min]="minDate" [max]="today"
                 (dateChange)="changeToDate($event)">
          <mat-datepicker-toggle matSuffix [for]="pickerFromDate"
                                 disabled="{{!(reqEditPermission |accessAllowed)}}"></mat-datepicker-toggle>
          <mat-datepicker #pickerFromDate disabled="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field *ngIf="onlyMonthAndYear" class="fromDatepicker">
          <mat-label>{{ "statistic.label.month" | translate }}</mat-label>
          <input matInput *ngIf="onlyMonthAndYear" [(ngModel)]="fromMonthAndYear" [matDatepicker]="pickerMonthAndYear"
                 disabled [min]="sixFinishedMonthsAgo" [max]="lastMonth" [monthFormat]="true" >
          <mat-datepicker-toggle matSuffix [for]="pickerMonthAndYear"
                                 disabled="{{!(reqEditPermission |accessAllowed)}}"></mat-datepicker-toggle>
          <mat-datepicker #pickerMonthAndYear disabled="false" startView="year"
                          (monthSelected)="setMonthAndYear($event, pickerMonthAndYear)"></mat-datepicker>
        </mat-form-field>

        <mat-form-field *ngIf="!isTimeIndependentQuery && !onlyMonthAndYear">
          <mat-label>{{ "statistic.label.todate" | translate }}</mat-label>
          <input matInput [(ngModel)]="toDate" [matDatepicker]="pickerToDate" disabled [min]="fromDate" [max]="maxDate">
          <mat-datepicker-toggle matSuffix [for]="pickerToDate"
                                 disabled="{{!(reqEditPermission |accessAllowed)}}"></mat-datepicker-toggle>
          <mat-datepicker #pickerToDate disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <mat-action-row>
        <div *ngIf="statusService.currentQuery" class="near-spinner">
          {{'statistic.report.currentlyDownloading' | translate}}
          : {{('statistic.report.' + statusService.currentQuery) | translate }}
        </div>
        <div *ngIf="statusService.currentChunk===1" class="near-spinner">
          {{statusService.currentChunk}} {{'statistic.label.chunk-loaded' | translate }}
        </div>
        <div *ngIf="statusService.currentChunk>1" class="near-spinner">
          {{statusService.currentChunk}} {{'statistic.label.chunks-loaded' | translate }}
        </div>
        <mat-spinner [diameter]="30" [strokeWidth]="5"
                     *ngIf="statusService.downloadInProgress"></mat-spinner>
        <button okAction class="normalButton" mat-stroked-button (click)="exportReport()"
                [disabled]="statusService.downloadInProgress || pageSize.invalid || !(reqEditPermission | accessAllowed)">
          <span>{{ "statistic.button.export" | translate }}</span>
          <mat-icon>insert_chart_outlined</mat-icon>
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</div>
